import React, { Component } from "react";
import "./text.scss";
import axios from "axios";
import { apiPrefix, baseTitle } from "../../helper";
import { Helmet } from "react-helmet";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "../login/actions/authActions";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

const TITLE = `${baseTitle} Test`;

class Test extends Component<
  Props,
  { value: any; intent: any; confidence: any }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: null,
      intent: null,
      confidence: []
    };
  }

  getConfidence = async (e: any) => {
    try {
      if (this.state.value && e.key === "Enter") {
        let url = apiPrefix + `wit/search/${this.state.value}`;
        let headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`
        };
        let response: any = await axios({
          method: "get",
          url: url,
          headers: headers
        });
        console.log("Get Confidence: ", response.data.data);
        if (response.data.success) {
          let data = response.data.data;
          this.setState({
            intent: data.intent.intent,
            confidence: data.intent.confidence
          });
        }
      } else {
        console.log("please type something");
      }
    } catch (error) {}
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>

          <div className="container">
            <div className="main_text">
              <div className="main_text_header">
                <img src="assets/text.png" />
                <h4>Test how your bot understands a sentence</h4>
              </div>
              <div className="main_text_body">
                <div className="sub_main_text_header">
                  <img src="assets/user_say.png" />
                  <input
                    onChange={e => this.setState({ value: e.target.value })}
                    onKeyDown={this.getConfidence}
                    type="text"
                    placeholder="If user says"
                  />
                </div>

                <div className="text">
                  <ul>
                    <li>{this.state.intent}</li>
                  </ul>
                </div>
                <div className="dropdown_text_item">
                  <div className="collapse_heading">
                    <h5 data-target="#text_collapse">Intent</h5>
                    <p>Matched Type</p>
                  </div>
                  <div id="text_collapse" className="">
                    <ul>
                      {this.state.confidence.map((confidence, i) => (
                        <li>
                          <p>{confidence}</p>
                          <span
                            className={
                              i == 0 ? "green" : i == 1 ? "yellow" : "red"
                            }
                            title={
                              i == 0 ? "High" : i == 1 ? "Medium" : "Low"
                            }
                          ></span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Test);
